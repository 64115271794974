import moment from 'moment'
import config from '@/configurations/config.js'
import authenticationProviders from '@/utils/authenticationProvider'
let authenticationProvider = authenticationProviders[config.platform].default

export default {
  ready () {
    let lang = authenticationProvider.getAuthenticatedUser().language
    moment.locale(lang)
  },
  formatDate (originDate) {
    return moment(originDate).format('l LT')
  },
  formatDateOnly (originDate) {
    return moment(originDate).format('l')
  },
  generateDateLastChangedValue () {
    return moment().utc().add(3, 'hour').format('YYYY-MM-DDTHH:mm:ss')
  },
  formatIfDate (possibleDate, fieldName) {
    var date = moment(possibleDate)
    let knownDateFields = ['PrDateLastChanged']
    // if (date.isValid()) {  // Has unwanted side-effects (formatting non-dates)
    if (knownDateFields.includes(fieldName)) {
      return date.format('l LT')
    } else {
      return possibleDate
    }
  }
}
