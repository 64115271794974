import cookie from '@/utils/cacheProviders/cookieCacheProvider'
import stringUtils from '@/utils/stringUtils'
import config from '@/configurations/config.js'

import TableColumn from '@/components/dynamicTables/TableColumn'
import { DxLookupProperty } from './DxLookupProperty'
import { DxOption } from './DxOption'
import sortUtils from '@/utils/sortUtils.js'

export default class GuiTemplateParser {
  static parseTemplate(guiTemplate, additionalData) {
    const languageCode = stringUtils.convert2To3LetterLanguageCode(cookie.get('language'))

    const tableColumns = []

    if (!guiTemplate || !guiTemplate.controls) {
      console.log('guiTemplate or its controls not found in GuiTemplateParser')
      return
    }

    guiTemplate.controls
      .sort((a, b) => (parseInt(a.order) > parseInt(b.order)) ? 1 : -1)
      .forEach(control => {
        if (control.inputControlOptions && control.inputControlOptions.SystemField && control.inputControlOptions.SystemField.toString().toLowerCase() === 'true') {
          return
        }

        const tableColumn = this.createTableColumn(control, languageCode, additionalData)

        tableColumns.push(tableColumn)
      })

    return tableColumns
  }

  static createTableColumn(control, languageCode, additionalData) {
    const caption = control.labels[0][languageCode]
    const dataField = control.fields[0]
    const editor = config.configEditors(dataField.type)
    const dataType = editor.dataType

    const tableColumn = new TableColumn(dataType, caption, dataField.key)

    if (this.isRequired(dataField)) {
      tableColumn.validations.dxRequireRule = true
    }

    if (editor.options && editor.options.isLookup) {
      this.addOptionsToLookupColumn(tableColumn, dataField, editor, additionalData, control, languageCode)
    }

    if (control.inputControlOptions.MinLength >= 0) {
      tableColumn.validations.dxStringLengthRule = true
      tableColumn.validations.minStringLength = parseInt(control.inputControlOptions.MinLength)
    }

    if (control.inputControlOptions.MaxLength >= 0) {
      tableColumn.validations.dxStringLengthRule = true
      tableColumn.validations.maxStringLength = parseInt(control.inputControlOptions.MaxLength)
    }

    if (control.inputControlOptions.MinValue >= 0) {
      tableColumn.validations.dxNumberRangeRule = true
      tableColumn.validations.minNumberValue = parseInt(control.inputControlOptions.MinValue)
    }

    if (control.inputControlOptions.Maxvalue >= 0) {
      tableColumn.validations.dxNumberRangeRule = true
      tableColumn.validations.maxNumberValue = parseInt(control.inputControlOptions.MaxValue)
    }

    if (control.inputControlOptions.Disabled) {
      tableColumn.disabledForEditing = true
    }

    if (control.inputControlOptions.StartInColumnPicker === 'true') {
      tableColumn.visible = false
    }

    return tableColumn
  }

  static addOptionsToLookupColumn(tableColumn, dataField, editor, additionalData, control, languageCode) {
    tableColumn.validations.dxLookup = true
    let lookupOptions = []

    if (!this.isRequired(dataField)) {
      lookupOptions.push(new DxOption('', ''))
    }

    if (editor.name === 'subLocationListEditor') {
      lookupOptions.push(...this.convertObjectsToDxOptions(additionalData && additionalData.sublocations ? additionalData.sublocations : [], 'SlID', 'SlName'))
      tableColumn.validations.dxLookupValidationProperties = new DxLookupProperty(lookupOptions)
    } else if (this.isControlWithOptionsWithDescriptionsAsKeys(control)) {
      lookupOptions.push(...this.convertFieldOptionsToDxOptionsWithDescriptionsAsKeys(dataField.options, languageCode))
      tableColumn.validations.dxLookupValidationProperties = new DxLookupProperty(lookupOptions)
      tableColumn.calculateDisplayValue = (dataRow) => {
        return dataRow[tableColumn.dataFieldKey]
      }
    } else {
      lookupOptions.push(...this.convertFieldOptionsToDxOptions(dataField.options, languageCode))
      tableColumn.validations.dxLookupValidationProperties = new DxLookupProperty(lookupOptions)
    }
  }

  static isRequired(dataField) {
    return dataField.requiredBySystem || dataField.requiredByUser
  }

  static isControlWithOptionsWithDescriptionsAsKeys(control) {
    if (!control.fields) return false
    return ['3'].includes(control.fields[0].type)
  }

  static convertFieldOptionsToDxOptionsWithDescriptionsAsKeys(options, languageCode) {
    let items = []
    options.forEach(option => {
      let description = option.text[0][languageCode]
      items.push(new DxOption(description, description))
    })
    items.sort((firstItem, secondItem) => sortUtils.naturalSort(firstItem.description, secondItem.description))
    return items
  }

  static convertFieldOptionsToDxOptions(options, languageCode) {
    let items = []
    options.forEach(option => {
      let description = option.text[0][languageCode]
      let key = option.value
      items.push(new DxOption(key, description))
    })
    items.sort((firstItem, secondItem) => sortUtils.naturalSort(firstItem.description, secondItem.description))
    return items
  }

  static convertObjectsToDxOptions(dataObjects, dataKey, displayKey) {
    let items = []
    dataObjects.forEach(item => {
      let description = item[displayKey]
      let key = item[dataKey]
      items.push(new DxOption(key, description))
    })
    items.sort((firstItem, secondItem) => sortUtils.naturalSort(firstItem.description, secondItem.description))
    return items
  }

  static convertGcOptionsToDxOptions(options) {
    let items = []
    options.forEach(option => {
      let description = option.GcDescription
      let key = option.GcCode
      items.push(new DxOption(key, description))
    })
    items.sort((firstItem, secondItem) => sortUtils.naturalSort(firstItem.description, secondItem.description))
    return items
  }
}
