export class DxLookupProperty {
  options; // An array containing the lookup list options
  displayProperty; // The property of the display value in the option object
  valueProperty; // The property of the value in the option object

  constructor (options, displayProperty = 'description', valueProperty = 'key') {
    this.options = options
    this.displayProperty = displayProperty
    this.valueProperty = valueProperty
  }
}
