export default class TableColumn {
  caption; // The text shown in the column header
  dataType; // The datatype of the column
  dataFieldKey; // The key of the property in the data object
  validations;
  disabledForEditing;
  visible;

  constructor (dataType, caption, dataFieldKey) {
    this.dataType = dataType
    this.caption = caption
    this.dataFieldKey = dataFieldKey
    this.disabledForEditing = false
    this.visible = true
    this.validations = {
      dxRequireRule: false,
      dxStringLengthRule: false,
      dxPatternRule: false,
      dxAsyncRule: false,
      dxLookup: false,
      dxLookupValidationProperties: {
        options: [],
        displayProperty: '',
        valueProperty: ''
      }
    }
  }
}
