<template>
  <div
    class="popupModal"
    @click.self="emitCloseButtonClicked"
  >
    <div class="popupBody">
      <div class="popupHeader">
        <div class="popup-title-container">
          <div class="popup-title">
            {{ popupTitle }}
          </div>
          <div class="popup-sub-title">
            {{ popupSubTitle }}
          </div>
        </div>
        <font-awesome-icon
          ref="closeButton"
          focusable="true"
          :tab-index="0"
          icon="fa-solid fa-xmark"
          class="popupClose"
          @click="emitCloseButtonClicked"
        />
      </div>
      <div class="popupContent">
        <slot />
      </div>
      <template v-if="hasFooterSlot">
        <slot name="footer" />
      </template>
      <template v-else-if="hasRightFooterButton">
        <div class="popupFooter">
          <div class="popup-footer-left">
            <button
              v-if="hasLeftFooterButton"
              class="footerButton left"
              :tab-index="0"
              :disabled="!leftFooterButtonActive"
              @click="onLeftFooterButtonClick">
              <font-awesome-icon v-if="leftFooterButtonIcon" :icon="leftFooterButtonIcon" />
              {{ $t(leftFooterButtonText) }}
            </button>
          </div>
          <div class="popup-footer-right">
            <p class="popup-footer-text">
              {{ rightFooterText }}
            </p>
            <p v-if="createError" class="popupFooterError">
              {{ customErrorMessage || $t('project.ErrorMessage') }}
            </p>
            <button
              v-if="rightFooterButtonText"
              ref="rightFooterButton"
              class="footerButton right"
              :tab-index="1"
              :disabled="createError || !rightFooterButtonActive"
              @click="onRightFooterButtonClick">
              {{ $t(rightFooterButtonText) }}
              <font-awesome-icon v-if="rightFooterButtonIcon" :icon="rightFooterButtonIcon" />
            </button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePopup',
  emits: ['closeButtonClicked', 'rightButtonClicked'],
  props: {
    popupOpen: {
      type: Boolean,
      required: true
    },
    createError: {
      type: Boolean,
      required: false,
      default: false
    },
    customErrorMessage: {
      type: String,
      required: false,
      default: ''
    },
    popupTitle: {
      type: String,
      required: false,
      default: 'add'
    },
    popupSubTitle: {
      type: String,
      required: false
    },
    rightFooterButtonText: {
      type: String,
      required: false
    },
    rightFooterButtonIcon: {
      type: String,
      required: false
    },
    rightFooterButtonActive: {
      type: Boolean,
      required: false,
      default: true
    },
    leftFooterButtonText: {
      type: String,
      required: false
    },
    leftFooterButtonIcon: {
      type: String,
      required: false
    },
    leftFooterButtonActive: {
      type: Boolean,
      required: false,
      default: true
    },
    rightFooterText: {
      type: String,
      required: false,
      default: ''
    },
    canClose: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
    hasRightFooterButton() {
      return this.rightFooterButtonText || this.rightFooterButtonIcon
    },
    hasLeftFooterButton() {
      return this.leftFooterButtonText || this.leftFooterButtonIcon
    }
  },
  mounted() {
    if (this.hasFooterSlot) return
    if (!this.hasRightFooterButton) return
    window.addEventListener('keyup', this.onKeyUp)
    this.$refs.rightFooterButton.addEventListener('focusout', this.restartPopupFocus)
  },
  methods: {
    restartPopupFocus() {
      if (!this.popupOpen) return
      this.$refs.closeButton.focus()
    },
    onKeyUp(event) {
      if (!this.popupOpen) return
      if (event.keyCode !== 27) return
      this.$emit('closeButtonClicked')
    },
    onRightFooterButtonClick() {
      this.$emit('rightButtonClicked')
    },
    onLeftFooterButtonClick() {
      this.$emit('leftButtonClicked')
    },
    emitCloseButtonClicked() {
      if (this.popupOpen && this.canClose) {
        this.$emit('closeButtonClicked')
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyUp, { passive: true })
  }
}
</script>

<style lang="less" scoped>
.popupModal {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 150vh;
  position: absolute;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 101;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;

  .popupBody {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    min-height: 100px;
    max-width: 95vw;
    border-radius: 6px;
    background-color: white;
    z-index: 102;
    margin-top: 5vh;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

    .popupHeader {
      background-color: #67ac45;
      display: flex;
      justify-content: space-between;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .popup-title-container {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .popup-title {
          margin: 0;
          align-self: center;
          color: white;
          font-size: 18px;
          font-weight: 600;
        }

        .popup-sub-title {
          margin: 0;
          align-self: center;
          color: white;
          font-size: 18px;
        }
      }

      .popupClose {
        cursor: pointer;
        height: 18px;
        padding: 9px 11px;
        color: white;
        border-radius: 4px;
        margin-left: 10px;

        &:hover,
        &:focus {
          background-color: @hover-with-background;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .popupContent {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      p {
        color: black;
      }

      .popupInput {
        color: black;
      }
    }

    .popupFooter {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding: 15px;
      display: flex;
      border-top: 1px solid #d5d5d5;

      .popup-footer-left {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      .popup-footer-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .popup-footer-text {
        margin: 0 10px 0 0;
        font-size: 16px;
        color: @gray500;
        font-weight: 500;
      }

      .popupFooterError {
        margin: 0 10px 0 0;
        color: @error600;
      }

      .footerButton {
        padding: 6px 12px;
        font-size: 14px;
        font-weight: bold;
        height: fit-content;
        border-radius: 4px;
        border: none;

        &.right {
          margin-left: 5px;
          color: white;
          background-color: #67ac45;

          &:hover,
          &:focus {
            background-color: #35943b;
          }

          &:active {
            background-color: #2e7f34;
          }

          &:disabled {
            background-color: @gray300;
            cursor: not-allowed;
          }
        }

        &.left {
          background-color: white;
          border: 1px solid @gray300;
          color: @gray600;

          &:hover,
          &:focus {
            background-color: @gray100;
          }

          &:active {
            background-color: @gray200;
          }

          &:disabled {
            background-color: @gray300;
            color: white;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
</style>
